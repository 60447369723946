<template>
  <div class="container-wrap">
    <Header></Header>
    <b-container>
      <div class="bread-crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>全部商品</el-breadcrumb-item>
          <el-breadcrumb-item>灯</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="select-option-wrap">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item v-for="(item,index) in form.classify" :label="item.name_ + '：'" :key="index">
            <el-checkbox-group v-model="form.typeIds" @change="selectType">
              <el-checkbox v-for="(it,idx) in item.subList" :label="it.id_" :key="idx">{{it.name_}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="solution-box video-box" v-if="goodsList.length > 0">
        <waterfall :col="colCount" :data="goodsList" class="solution">
          <template>
            <div
              class="solution-item"
              v-for="(item, index) in goodsList"
              :key="index"
              @click="toProductDetail(item)"
            >
              <div class="solution-item-ehome">
                <div class="solution-img">
                  <img class="mode-img" :src="item.main_img_" />
                </div>
                <!-- @click.stop="collectionMode(item)" -->
                <!-- <div
                  class="collection-button"
                  
                >
                  <i class="el-icon-star-off" v-if="item.collections == 0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collections == 0">
                    {{ $t("collectionButtonTitle") }}
                  </div>
                  <div class="collection-msg" v-else>
                    {{ $t("cancleButtonTItle") }}
                  </div>
                </div> -->
                <div class="solution-name ehome-solution-title-name">{{item.name_}}</div>
                <div class="solution-user-box">¥{{ item.price_ }}</div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <div class="no-data" v-else>
        <i class="el-icon-search"></i>
        <h2>抱歉，没有找到与“<span>{{searchBlockName.join('，')}}</span>”相关的商品</h2>
      </div>
      <el-pagination
        v-if="totalPage > 0"
        :page-count="totalPage"
        @current-change="changeCurrentPage"
        class="margin-top-80"
        background
        layout="prev, pager, next"
      ></el-pagination>
    </b-container>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "@/components/Navbar.vue";
  import Footer from "@/components/Footer.vue";
  import Right from "@/components/Right.vue";
  import dump from "@/util/dump";
  export default {
    components: {
      Header,
      Footer,
      Right
    },
    data (){
      return {
        form: {
          typeIds: [],
          classify: []
        },
        colCount: 4,
        goodsList: [],
        currentPage: 1,
        totalPage: 0,
        searchBlockName: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.getGoodsClassify();
        this.getGoodsList();
      },
      // 获取灯无忧产品分类
      getGoodsClassify(){
        // 重组风格数据
        this.$api.deng51GoodsClassify({}).then(res => {
          res.data.forEach(item => {
            if(item.styleTypeDtoList){
              // 新建一个subList项
              item.subList = [];
              item.styleTypeDtoList.forEach(it => {
                // 把得到的数组展开添加到新建的subList项中
                item.subList.push(...it.subList)
              })
            }
          })
          this.form.classify = res.data;
        }).catch(err => console.error(err))
      },
      // 获取灯无忧产品列表
      getGoodsList(){
        this.$api.deng51GoodsList({
          currentPage: this.currentPage,
          showCount: 20,
          type_ids_: this.form.typeIds.join(',')
        }).then(res => {
          this.goodsList = res.data.pdList;
          // 商品总页数
          this.totalPage = res.data.totalPage;
        }).catch(err => {
          console.error(err);
        })
      },
      // 产品切换分页
      changeCurrentPage(val){
        console.log(val)
        this.currentPage = val;
        this.getGoodsList();
        this.$utils.scrollTop();
      },
      // 选择分类
      selectType(){
        // console.log("val=",val.join(','), "this.form.typeIds=",this.form.typeIds.join(','))
        this.getGoodsList();
        this.searchBlockName = [],
        this.form.classify.forEach(item => {
          item.subList.forEach(it => {
            this.form.typeIds.forEach(id => {
              if(it.id_ == id){
                this.searchBlockName.push(it.name_)
              }
            })
          })
        })
        console.log(this.searchBlockName.join('，'))
      },
      // 跳转到商品详情页
      toProductDetail(obj){
        dump.link({
          type: 1,
          link: "/deng51goodsdetail",
          params: {
            goodsId: obj.id_
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "deng51"
</style>
